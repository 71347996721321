body {
  perspective: 300rem;
}

.remoteWidget {
  grid-template-rows: 3rem 1fr 3rem;
  max-width: 700px;
}

.remoteWidgetBody {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.remoteWidgetHeader {
  grid-template-columns: 45% 45% 10%;
}

.widgetAsLink {
  position: static;
  font-size: 1em;
}

.remoteWidgetFooter {
  grid-template-columns: 1fr 1.2fr;
}
.remoteWidgetFooter p {
  font-size: 0.85em;
  font-style: italic;
}
.SubWidget {
  opacity: 1;
  width: 6.7rem;
  height: 6.7rem;
  grid-template-rows: 0.9fr repeat(2, 1fr);
  grid-template-areas:
    "widgetHeading"
    "widgetBody"
    "widgetBody";
  animation: float 1.5s ease-in-out;
  animation-fill-mode: both;
}

.delay1 {
  animation-delay: 0.5s;
}
.delay2 {
  animation-delay: 0.8s;
}
.delay3 {
  animation-delay: 1.1s;
}
.delay4 {
  animation-delay: 1.4s;
}
.delay5 {
  animation-delay: 1.7s;
}

@keyframes float {
  0% {
    transform: scale(0.2) rotateZ(-90deg);
    opacity: 0;
  }
  75% {
    opacity: 1;
    transform: scale(1.1) rotateZ(15deg);
  }
  100% {
    transform: scale(1) rotateZ(0deg);
  }
}

.SubWidget h4 {
  grid-area: widgetHeading;
  grid-row: span 1;
}

.SubWidget .content {
  grid-area: widgetBody;
  grid-row: span 2;
}

.content .Inputs {
  grid-template-columns: repeat(4, 1fr);
}

.content .Outputs {
  grid-template-columns: 1fr 1fr;
}

.SubWidget .OutputCircle {
  margin: 10px;
}
.statusWidget {
  font-size: 3em;
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 750px) {
  .widgetAsLink {
    width: 100%;
  }
  .remoteWidget {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 5fr 0.5fr;
    max-width: 90vw;
  }
  .remoteWidgetBody {
    grid-template-columns: 90%;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  }
  .remoteWidgetFooter {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 50%;
  }
  .SubWidget {
    grid-template-rows: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "widgetHeading widgetBody";
    width: 100%;
    height: 3.5em;
    animation: none;
  }
}
