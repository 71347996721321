.topnavtitle {
  grid-area: title;
  display: grid;
  place-content: center;
}

.topnavLogo {
  position: static;
  grid-area: logo;
  margin: 0;
  padding: 0;
  opacity: 1;
  display: grid;
  place-content: center;
  place-items: center;
}
.logoutButtonLink {
  position: static;
  font-size: 1em;
}

.topnavLogo img {
  content: var(--logo);
  width: 100%;
  padding: 0.5rem;
}
.topnavLogoPrio img {
  content: url(prioSafe.png);
  width: 100%;
}

.topnavLinkSection {
  display: inline;
  margin-left: 5vw;
  float: left;
}

.topnavUserSection {
  display: inline;
  float: right;
}

.menuButton {
  grid-area: menu;
  color: gray;
  display: grid;
  place-content: center;
  font-size: 38px;
}

.logoutButton {
  color: var(--style-gray);
  grid-area: logout;
  border-radius: 15px;
  opacity: 0.5;
  cursor: pointer;
  place-items: center;
  display: grid;
}

.logoutButton:hover {
  opacity: 1;
}

@media only screen and (max-width: 900px) {
  .topnavLogo img {
    content: var(--icon);
    height: 50px;
    max-width: 50px;
  }
}
