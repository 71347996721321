/*--- Colors ---*/
.orange-1000 {
  color: var(--orange-1000);
}
.orange-900 {
  color: var(--orange-900);
}
.orange-800 {
  color: var(--orange-800);
}
.orange-600 {
  color: var(--orange-600);
}
.orange-500 {
  color: var(--orange-500);
}
.orange-400 {
  color: var(--orange-400);
}
.orange-200 {
  color: var(--orange-200);
}

.gray-900 {
  color: var(--gray-900);
}
.gray-800 {
  color: var(--gray-800);
}
.gray-600 {
  color: var(--gray-600);
}
.gray-400 {
  color: var(--gray-400);
}

.red {
  color: red;
}

.green {
  color: green;
}

.bg-black {
  background-color: var(--bg-black);
}
.bg-gray {
  background-color: var(--bg-gray);
}

/*--- Text ---*/
.bold {
  font-weight: bold;
}

.fontSize05 {
  font-size: 0.5rem;
}
.fontSize08 {
  font-size: 0.8rem;
}
.fontSize10 {
  font-size: 1rem;
}
.fontSize11 {
  font-size: 1.1rem;
}
.fontSize12 {
  font-size: 1.2rem;
}
.fontSize15 {
  font-size: 1.5rem;
}
.fontSize20 {
  font-size: 2rem;
}

/*--- Placement ---*/

.placeContentStart {
  display: grid;
  place-content: start;
}

.placeSelfEnd {
  place-self: end;
}
.placeSelfCenter {
  place-self: center;
}

.gridItemsCenter {
  display: grid;
  place-items: center;
}

.alignItemsCenter {
  align-items: center;
}
.gap20 {
  gap: 2rem;
}
.gap10 {
  gap: 1rem;
}
.gap05 {
  gap: 0.5rem;
}

.span2 {
  grid-column: span 2;
}
.span3 {
  grid-column: span 3;
}
.span4 {
  grid-column: span 4;
}

/*--- Padding ---*/

.paddingTopLeft10 {
  padding-top: 1rem;
  padding-left: 1rem;
}

.paddingLeft10 {
  padding-left: 1rem;
}
.paddingLeft05 {
  padding-left: 0.5rem;
}

.padding00 {
  padding: 0;
}

.padding05 {
  padding: 0.5rem;
}
.padding10 {
  padding: 1rem;
}
.padding15 {
  padding: 1.5rem;
}
.padding20 {
  padding: 2rem;
}

.paddingBoAuto {
  padding-bottom: auto;
}

/*--- Margin ---*/
.marginTop01 {
  margin-top: 0.1rem;
}
.marginTop02 {
  margin-top: 0.2rem;
}
.marginTop03 {
  margin-top: 0.3rem;
}
.marginTop04 {
  margin-top: 0.4rem;
}
.marginTop05 {
  margin-top: 0.5rem;
}
.marginTop10 {
  margin-top: 1rem;
}
.marginTop20 {
  margin-top: 2rem;
}

.marginBot01 {
  margin-bottom: 0.1rem;
}
.marginBot02 {
  margin-bottom: 0.2rem;
}
.marginBot03 {
  margin-bottom: 0.3rem;
}
.marginBot04 {
  margin-bottom: 0.4rem;
}
.marginBot05 {
  margin-bottom: 0.5rem;
}
.marginBot10 {
  margin-bottom: 1rem;
}
.marginBot20 {
  margin-bottom: 2rem;
}

.marginRight10 {
  margin-right: 1rem;
}

.margin10 {
  margin: 1rem;
}

.marginToBoAuto {
  margin-top: auto;
  margin-bottom: auto;
}
.marginLRAuto {
  margin-left: auto;
  margin-right: auto;
}

.marginLeft05 {
  margin-left: 0.5rem;
}
.marginLeft10 {
  margin-left: 1rem;
}
.marginLeft15 {
  margin-left: 1.5rem;
}

/*--- Cursor ---*/
.pointer {
  cursor: pointer;
}

/*--- Opacity ---*/
.opacity04 {
  opacity: 0.4;
}

.opacity00 {
  opacity: 0;
}

/*--- Animation ---*/

.resizeOnHover11 {
  transition: 0.6s ease;
}
.resizeOnHover11:hover,
.resizeOnHover11:focus {
  transform: scale(1.1);
  outline: none;
}
.resizeOnHover13 {
  transition: 0.6s ease;
}
.resizeOnHover13:hover,
.resizeOnHover13:focus {
  transform: scale(1.3);
  outline: none;
}

.bgColorOrangeOnHover {
  transition: 0.3s;
}
.bgColorOrangeOnHover:hover {
  background-color: var(--orange-200);
}

.resizeWithOpacity {
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0.5;
}

.resizeWithOpacity:hover {
  opacity: 1;
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
}
/*--- Text Align ---*/
.textAlignStart {
  text-align: start;
}
.textAlignCenter {
  text-align: center;
}
.textAlignEnd {
  text-align: end;
}

/*--- Text Decoration ---*/
.textDecLineThrough {
  text-decoration: line-through;
  text-decoration-thickness: 0.15rem;
  text-decoration-color: var(--orange-800);
}
.tdColGrey {
  text-decoration-color: var(--gray-800);
}
.textDecUnderLine {
  text-decoration: underline;
}

/*--- Border ---*/
.borderRadius10 {
  border-radius: 10px;
}
.borderRadius20 {
  border-radius: 20px;
}

/*--- Line Height ---*/
.lineHeight10 {
  line-height: 1rem;
}
.lineHeight15 {
  line-height: 1.5rem;
}
.lineHeight20 {
  line-height: 2rem;
}

/*--- Display ---*/
.displayInherit {
  display: inherit;
}
.displayBlock {
  display: block;
}

/*--- Height ---*/
.height100 {
  height: 100%;
}

/*--- Max-Height ---*/
.maxHeight2rem {
  max-height: 2rem;
}

.maxHeightMinContent {
  max-height: min-content;
}

.maxHeight500px {
  max-height: 400px;
}

/*--- Max-Width ---*/
.maxWidth300px {
  max-width: 300px;
}
.maxWidth400px {
  max-width: 400px;
}
.maxWidth500px {
  max-width: 500px;
}
.maxWidth600px {
  max-width: 600px;
}
.maxWidth700px {
  max-width: 700px;
}
.maxWidth750px {
  max-width: 750px;
}
.maxWidth800px {
  max-width: 800px;
}
.maxWidth5ch {
  max-width: 5ch;
}
.maxWidth10ch {
  max-width: 10ch;
}
.maxWidth15ch {
  max-width: 15ch;
}
.maxWidth20ch {
  max-width: 20ch;
}
.maxWidth25ch {
  max-width: 25ch;
}
.maxWidth30ch {
  max-width: 30ch;
}
.maxWidth35ch {
  max-width: 35ch;
}

/*--- Width ---*/

.width100px {
  width: 100px;
}
.width150px {
  width: 150px;
}
.width200px {
  width: 200px;
}
.width250px {
  width: 250px;
}
.width300px {
  width: 300px;
}
.width350px {
  width: 350px;
}
.width400px {
  width: 400px;
}

.minWidth100px {
  min-width: 100px;
}
.minWidth150px {
  min-width: 150px;
}
.minWidth200px {
  min-width: 200px;
}
.minWidth250px {
  min-width: 250px;
}
.minWidth300px {
  min-width: 300px;
}
.minWidth350px {
  min-width: 350px;
}
.minWidth400px {
  min-width: 400px;
}

.width100pro {
  width: 100%;
}
.width90pro {
  width: 90%;
}
.width80pro {
  width: 80%;
}
.width70pro {
  width: 70%;
}
.width60pro {
  width: 60%;
}
.width50pro {
  width: 50%;
}

/*--- Grid ---*/
.gridCol1x1fr {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.gridCol2x1fr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.gridCol3x1fr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.gridCol4x1fr {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.gridCol5x1fr {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.gridCol1fr200px {
  display: grid;
  grid-template-columns: 1fr 200px;
}
.gridCol1fr300px {
  display: grid;
  grid-template-columns: 1fr 300px;
}
.gridCol1fr400px {
  display: grid;
  grid-template-columns: 1fr 400px;
}
.gridCol1fr500px {
  display: grid;
  grid-template-columns: 1fr 500px;
}
.gridCol1fr600px {
  display: grid;
  grid-template-columns: 1fr 600px;
}

.gridCol02ch1fr {
  display: grid;
  grid-template-columns: 02ch 1fr;
}
.gridCol03ch1fr {
  display: grid;
  grid-template-columns: 03ch 1fr;
}
.gridCol04ch1fr {
  display: grid;
  grid-template-columns: 04ch 1fr;
}
.gridCol05ch1fr {
  display: grid;
  grid-template-columns: 05ch 1fr;
}
.gridCol06ch1fr {
  display: grid;
  grid-template-columns: 06ch 1fr;
}
.gridCol07ch1fr {
  display: grid;
  grid-template-columns: 07ch 1fr;
}
.gridCol08ch1fr {
  display: grid;
  grid-template-columns: 08ch 1fr;
}
.gridCol09ch1fr {
  display: grid;
  grid-template-columns: 09ch 1fr;
}
.gridCol10ch1fr {
  display: grid;
  grid-template-columns: 10ch 1fr;
}
.gridCol11ch1fr {
  display: grid;
  grid-template-columns: 11ch 1fr;
}
.gridCol12ch1fr {
  display: grid;
  grid-template-columns: 12ch 1fr;
}
.gridCol13ch1fr {
  display: grid;
  grid-template-columns: 13ch 1fr;
}
.gridCol14ch1fr {
  display: grid;
  grid-template-columns: 14ch 1fr;
}
.gridCol15ch1fr {
  display: grid;
  grid-template-columns: 15ch 1fr;
}
.gridCol20ch1fr {
  display: grid;
  grid-template-columns: 20ch 1fr;
}
.gridCol25ch1fr {
  display: grid;
  grid-template-columns: 25ch 1fr;
}
.gridCol30ch1fr {
  display: grid;
  grid-template-columns: 30ch 1fr;
}

/*--- Flex ---*/
.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flexNoWrap {
  flex-wrap: nowrap;
}
.flexCenter {
  align-items: center;
  justify-content: center;
}
.flexAlignCenter {
  align-items: center;
}
.flexSpaceBetween {
  justify-content: space-between;
}
.flexSpaceAround {
  justify-content: space-around;
}
.flexSpaceEvenly {
  justify-content: space-evenly;
}
.flexFlexStart {
  justify-content: flex-start;
}
.flexFlexEnd {
  justify-content: flex-end;
}

.flexGrow {
  flex-grow: 1;
}

/*--- TextDecoration ---*/
.textUnderline {
  text-decoration: underline;
}

/*--- ZIndex ---*/

.zIndex1 {
  z-index: 1;
}

/*--- BoxSizing ---*/
.contentBox {
  box-sizing: content-box;
}
.borderBox {
  box-sizing: border-box;
}

@media only screen and (max-width: 450px) {
  .padding10 {
    padding: 1rem 0.6rem;
  }
  .margin10 {
    margin: 1rem 0.5rem;
  }

  /*--- Grid ---*/
  .gridCol1x1frOn450px {
    grid-template-columns: 1fr;
  }
}

/*--- visibility ---*/
.hidden {
  visibility: hidden;
}
