.SettingsLandingPage {
  grid-template-areas:
    "details temp"
    "links inputs"
    "users outputs";
}

.modOverviewFragment {
}

.modOverviewFragment span {
  text-align: start;
  place-content: center;
  display: grid;
  grid-template-columns: 100px minmax(0, 100%);
}

.SettingsLandingPage .remoteBasicInfos {
  grid-area: details;
}
.SettingsLandingPage .remoteTemp {
  grid-area: temp;
}
.SettingsLandingPage .remoteInputs {
  grid-area: inputs;
}
.SettingsLandingPage .remoteOutputs {
  grid-area: outputs;
}
.SettingsLandingPage .remoteUsers {
  grid-area: users;
}
