.Main {
  border: 4px solid var(--orange-500);
  background-color: var(--bg-black);
  grid-area: content;
  border-radius: 30px;
  border-bottom: none;
  overflow: scroll;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 1rem;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    "output"
    "footer";
}

.Main::-webkit-scrollbar {
  display: none;
}

.RemoteDashboard {
  grid-template-columns: repeat(auto-fit, minmax(45rem, 1fr));
}

@media only screen and (max-width: 1078px) {
  .Main {
    grid-column: span 2;
  }
}
@media only screen and (max-width: 900px) {
  .Main {
    grid-column: span 3;
  }
}

@media only screen and (max-width: 450px) {
  .Main {
  }
  .RemoteDashboard {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
