/*------CONTAINER-----------------------*/

.remoteOutputSettingsFrame * {
  outline: none;
}
.remoteOutputSettingsFrame {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 600px));
  grid-gap: 1.5rem;
  padding: 1rem;
}

.widget {
  display: grid;
  box-shadow: 2px 2px 1px 1px rgba(255, 166, 0, 0.479);
  border-radius: 10px;
  background-color: rgba(85, 84, 84, 0.132);
  padding: 1rem;
  grid-gap: 0.5em;
  align-items: center;
}

.output-widget {
  display: grid;
  grid-template-areas:
    "index head head head head head test iconUP"
    "index head head head head head test iconUP"
    "index desc desc desc desc desc test iconPEN"
    "index desc desc desc desc desc test iconLO"
    "index desc desc desc desc desc test iconLO"
    "index desc desc desc desc desc test ."
    "index action action action action action test iconADD"
    "index action action action action action test iconADD"
    "index action action action action action test .";
  grid-template-rows: 1rem 1rem repeat(6, min-content) 1fr;
  grid-template-columns: repeat(6, 1fr) 2px 1fr;
}

.add-output-action {
  grid-template-columns: 7rem 1fr;
}
.add-output-action h3,
.add-output-action div {
  grid-column: span 2;
}

.add-output-action .abort-check {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: end;
  width: 100%;
  max-width: 100%;
}

.add-output-action select,
.add-output-action span {
  text-align: start;
  max-width: 15rem;
}

/*------BODY-----------------------*/

.output-widget .output-index {
  grid-area: index;
  place-self: center;
  font-size: 1.5em;
}

.output-widget hr {
  grid-area: test;
  background-color: rgba(218, 214, 214, 0.2);
  border: none;
  height: 80%;
  align-self: center;
}

.widget p,
.widget h3 {
  height: 100%;
  text-align: start;
}

.output-widget h3 {
  grid-area: head;
  max-height: 2em;
}

.output-widget p {
  grid-area: desc;
  max-height: 5rem;
}

.editable {
  background-color: rgba(85, 84, 84, 0.262);
  border-radius: 10px;
}

.editable:focus {
  background-color: rgba(255, 166, 0, 0.179);
}

.output-actions {
  grid-area: action;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2rem 1fr;
  grid-template-areas:
    "headerON headerOFF"
    "contentON contentOFF";
}

.output-actions ul {
  font-size: 0.9rem;
  text-align: start;
  list-style-type: none;
  color: rgba(218, 214, 214, 0.6);
}

.widget h5 {
  margin-top: 0.3rem;
}

.output-actions h5 {
  text-align: start;
}

.header-turnon {
  grid-area: headerON;
}
.header-turnoff {
  grid-area: headerOFF;
}
.content-turnon {
  grid-area: contentON;
}
.content-turnoff {
  grid-area: contentOFF;
}
.list-item-with-delete-option {
  display: flex;
  height: 1.3rem;
}
.list-item-with-delete-option p {
  margin-left: 3px;
  align-items: center;
}

/*------ICONS-----------------------*/

.output-icon {
  place-self: center;
  cursor: pointer;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0.5;
}

.output-icon:hover {
  opacity: 1;
  transform: scale(1.25);
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -o-transform: scale(1.25);
}

.settings {
  font-size: 1.5rem;
  color: orange;
}

.output-widget .output-switch {
  grid-area: iconUP;
  font-size: 2em;
}

.off {
  color: rgba(218, 214, 214, 1);
}
.on {
  color: orange;
}

.abort {
  font-size: 2rem;
  color: red;
  opacity: 0.5;
}
.save {
  font-size: 1.5rem;
  color: green;
  opacity: 0.5;
}

.iconUP {
  grid-area: iconUP;
}
.iconLO {
  grid-area: iconLO;
}

.iconAdd {
  grid-area: iconADD;
  font-size: 2rem;
  color: rgba(218, 214, 214, 1);
}

.output-widget .abort:hover,
.output-widget .save:hover {
  opacity: 1;
}

.red {
  color: red;
}

/*---SPINNER---------- */
.spinner {
  opacity: 1 !important;
  color: rgba(255, 166, 0, 0.479);
}

/*---MEDIA QUERIES ----------*/

@media only screen and (max-width: 450px) {
  .remoteOutputSettingsFrame {
    grid-template-columns: 1fr;
  }

  .output-widget {
    grid-template-columns: 0.3fr 1fr 1fr 2px 0.4fr;
    grid-template-rows: 2.5rem 3rem 2.5rem 1fr 2.5rem;
    grid-template-areas:
      "index head head test iconUP"
      "desc desc desc test ."
      "desc desc desc test iconLO"
      ". action action test ."
      ". action action test iconADD";
  }

  .output-actions {
    grid-template-columns: 1fr;
    grid-template-areas:
      "headerON"
      "contentON"
      "headerOFF"
      "contentOFF";
  }
}
