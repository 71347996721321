.App {
  width: 100%;
  height: 100%;
  text-align: center;
  display: grid;
  grid-template-areas:
    "logo title logout"
    "nav content navRight";
  grid-template-columns: 150px calc(100vw - 300px) 150px;
  grid-template-rows: 60px calc(100vh - (60px));
}

@media only screen and (max-width: 1078px) {
  .App {
    grid-template-areas:
      "logo title logout"
      "nav content content";
  }
}

@media only screen and (max-width: 900px) {
  .App {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 65px calc(var(--real-height) - 115px) 50px;
    grid-template-areas:
      "title title title"
      "content content content "
      "menu logo logout";
  }
}
