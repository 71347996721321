hr {
  width: 70%;
}

nav div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.sidenav {
  grid-area: nav;
}

.rightnav {
  grid-area: navRight;
}

.adaptiveLinks {
  margin-top: 2em;
}

.active {
  color: var(--active-Link);
}

@media only screen and (max-width: 1080px) {
  .rightnav {
    display: none;
  }
  .sidenav {
    display: flex;
  }
}

@media only screen and (max-width: 900px) {
  .sidenav {
    z-index: 1;
    position: fixed;
    top: 40px;
    display: none;
    flex-direction: column;
    height: calc(100vh - 115px);
    width: 100%;
    background-color: var(--bg-nav);
    line-height: 2;
    overflow: scroll;
  }
  .rightnav {
    display: none;
  }
}

/*
.sidenav  
    position: fixed;
    width: 150px;
    height: 100%;
*/
