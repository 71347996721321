.anlagenOverviewContainer {
  grid-template-columns: repeat(auto-fill, minmax(1fr, 45em));
}

.anlagenOverviewWidget {
  max-width: 700px;
  font-size: 1rem;
  display: grid;
  grid-template-columns: 5.5em 9.3em 6em 9em 6em 5em;
  grid-gap: 0.1em;
}

.anlagenOverviewWidget span {
  margin: 0.3em;
  display: flex;
  align-items: center;
}

.anlagenOverviewWidgetSub {
  grid-template-columns: 2em 1fr;
}
.anlagenOverviewWidgetSub p {
  margin-left: 0.4rem;
}

@media only screen and (max-width: 900px) {
  .anlagenOverviewWidget {
    grid-template-columns: 0.8fr 1fr 1fr;
    gap: 0;
  }
}
@media only screen and (max-width: 550px) {
  .anlagenOverviewWidget {
    grid-template-columns: 0.8fr 1fr;
    gap: 0;
  }
}
