.frame {
  background-color: var(--frame-bg);
  box-shadow: 3px 3px var(--gray-400);
  color: var(--orange-600);
  border-radius: 20px;
  padding: 1rem;
  padding-bottom: 2rem;
}

.frame[bottompadding="0"] {
  padding-bottom: 0;
}

.card {
  box-shadow: 2px 2px 1px 1px var(--orange-600);
  background-color: var(--bg-gray);
  border-radius: 10px;
  padding: 1rem;
}
