.anlagenDashBoard {
}

.anlagenDashBoard div {
  text-align: start;
}

.anlagenWidget {
  max-width: 500px;
  grid-template-rows: 30px 1fr;
}

.StatusEntry {
  grid-template-columns: 10rem 1fr;
}

.status-sonstiges {
  margin-top: 2rem;
  padding-left: 2rem;
  font-weight: 600;
}

.DatenEntry {
  grid-template-columns: 8.2rem 1fr;
}

.LogBuchEntry {
  grid-template-areas:
    "date  idtype"
    "time  content";
  grid-template-columns: 5rem 1fr;
  cursor: default;
}

.LogBuchZeit {
  grid-area: time;
  font-stretch: expanded;
}
.LogBuchDate {
  grid-area: date;
}
.LogbuchContent {
  grid-area: content;
}
.LogbuchID {
  grid-area: idtype;
}

.PowerUnitEntry {
  grid-template-columns: 10rem 1fr;
}

.abschaltungenEntry,
.komponentenEntry {
  grid-template-columns: 2rem 1fr;
}

.anlagenBodyColumTitel {
  font-weight: bold;
  color: rgb(99, 97, 97);
  margin-bottom: 0.5em;
}

.ImpedanzEntry,
.ImpedanzHeader {
  grid-template-columns: 0.5fr repeat(4, 1fr) 1.75fr;
}

.lsEntry,
.lsHeader,
.eingHeader,
.eingEntry {
  grid-template-columns: 1fr 2fr;
}

.ImpedanzEntry,
.lsEntry,
.eingEntry {
  cursor: default;
}

.schaltzeitenEntry {
  grid-template-columns: 1fr 1.5fr 1fr;
}

@media only screen and (max-width: 450px) {
  .LogBuchEntry {
    grid-gap: 0.3em;
  }
  .span2 {
    grid-column: span 1;
  }

  .lsLinienBezeichnung {
    display: none;
  }
  .ImpedanzEntry,
  .ImpedanzHeader {
    grid-template-columns: 0.5fr repeat(4, 1fr);
  }
}
