.searchContainer {
  position: relative;
  margin: 0.5em;
  max-width: 25ch;
}
.searchIcon {
  z-index: 2;
  float: right;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

.searchBarInput {
  padding-right: 2.5em;
}
