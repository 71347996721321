/*--- BASIC COLOR SCHEME ---*/

:root {
  --real-height: 100%;
}
:root.thum {
  --orange-1000: rgba(255, 165, 0, 1);
  --orange-900: rgba(255, 165, 0, 0.9);
  --orange-800: rgba(255, 165, 0, 0.8);
  --orange-600: rgba(255, 165, 0, 0.6);
  --orange-500: rgba(255, 165, 0, 0.5);
  --orange-400: rgba(255, 165, 0, 0.4);
  --orange-200: rgba(255, 165, 0, 0.2);

  --gray-900: rgba(201, 201, 201, 0.9);
  --gray-800: rgba(201, 201, 201, 0.8);
  --gray-600: rgba(201, 201, 201, 0.6);
  --gray-400: rgba(201, 201, 201, 0.4);

  --bg-black: rgb(5, 5, 5);
  --bg-gray: rgba(85, 84, 84, 0.132);
  --bg-nav: rgba(0, 0, 0, 0.85);

  --active-Link: var(--orange-1000);
  --frame-bg: var(--bg-black);

  --logo: url(transThumMit.png);
  --icon: url(../public/favicon.ico);
  font-size: 16px;
}
:root.priosafe {
  --orange-1000: rgba(0, 0, 0, 1);
  --orange-900: rgba(0, 0, 0, 1);
  --orange-800: rgba(0, 0, 0, 1);
  --orange-600: rgba(0, 0, 0, 1);
  --orange-500: rgba(0, 0, 0, 1);
  --orange-400: rgba(0, 0, 0, 1);
  --orange-200: rgba(0, 0, 0, 1);

  --gray-900: rgba(0, 51, 106, 1);
  --gray-800: rgba(0, 51, 106, 1);
  --gray-600: rgba(0, 51, 106, 1);
  --gray-400: rgba(0, 51, 106, 1);

  --bg-black: rgba(0, 51, 106, 0.15);
  --bg-gray: rgba(0, 51, 106, 0.4);
  --bg-nav: rgba(255, 255, 255, 0.85);
  --active-Link: rgba(229, 123, 0, 1);
  --frame-bg: rgba(0, 51, 106, 0.1);
  --prios-orange: rgba(229, 123, 0, 1);
  --prios-blue: rgba(0, 51, 106, 1);

  --logo: url(prioSafe.png);
  --icon: url(../public/priosafe.png);

  font-size: 16px;
}
