

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*--- HEADER TAGS ---*/

h1 {
  font-size: 1.5rem;
  color: var(--gray-800);
}

h3 {
  color: var(--orange-600);
  font-size: 1.2rem;
  text-align: start;
}

h4 {
  font-size: 1rem;
  color: var(--gray-600);
}

h5 {
  color: var(--orange-900);
  font-size: 0.8rem;
}

/*--- Anchor Tags ---*/

a {
  color: var(--gray-900);
  text-decoration: none;
  font-weight: bold;
  font-size: 0.8rem;
}

a:hover {
  color: var(--gray-400);
}

/*--- Input Tags --*/

input,
select,
textarea {
  background-color: var(--bg-black);
  color: var(--orange-800);
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  box-shadow: 2px 2px var(--orange-200);
  border-radius: 5px;
  font-weight: bold;
}

input[type="number"] {
  appearance: textfield;
}

textarea {
  resize: none;
  min-height: 10rem;
  overflow: hidden;
}

/*--- Label Tags --*/

label {
  color: var(--gray-600);
  font-size: 0.85rem;
  font-weight: bold;
  float: left;
  margin-bottom: 0.5em;
  place-self: start;
}

/*--- Button Tags --*/

button {
  background-color: var(--bg-black);
  color: var(--orange-800);
  outline: none;
  border: 2px solid var(--gray-800);
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  float: right;
  opacity: 0.5;
  padding: 0.3rem;
  border-radius: 1rem;
  width: fit-content;
  min-width: 10rem;
  max-width: 30ch;
  margin-top: 1rem;
  place-self: end;
}

button:hover {
  opacity: 1;
}

/*--- hr Tag ---*/

hr {
  border: none;
  outline: none;
  background-color: var(--gray-800);
}

hr[orientation="horizontal"] {
  height: 1px;
  width: 80%;
  margin: 0.3em auto;
}
hr[orientation="vertical"] {
  height: 80%;
  width: 1px;
}

/*--- Navigation ---*/

nav {
  margin-top: 1.5rem;
}

/*--- Form ---*/

form {
  display: grid;
  gap: 1em;
}

textarea {
  display: grid;
}

/*--- DIV ---*/
div {
  display: grid;
}

/*--- List ---*/
ul {
  list-style-type: none;
}

/*--- Table ---*/
table {
  display: grid;
  gap: 0.5em;
  table-layout: fixed;
  width: 100%;
}
tr {
  display: grid;
  gap: 1rem;
  text-align: start;
  grid-template-columns: 7ch repeat(5, minmax(5ch, 1fr));
  white-space: nowrap;
  line-height: 1.3rem;
}
th {
  text-align: start
}
td{
  text-align: start;
}