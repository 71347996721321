
body {
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: var(--bg-black);
  margin-bottom: 100px;
  overflow: hidden;
}

a {
  position: static !important;
}

footer {
  position: fixed;
  bottom: 1em;
  align-items: center;
  color: gray;
}

code {
  font-family: "Arial";
}

*::selection {
  color: black;
  background-color: orange;
}
