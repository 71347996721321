.basic-header {
  text-align: start;
}
.remoteInputSettingsHead {
  text-align: start;
}

.remoteInputSettingsBody {
  display: grid;
}

.input-widget {
  max-width: 600px;
  display: grid;
  grid-template-areas:
    "index mail head hr setting"
    "index mail content hr setting"
    "index mail content hr setting";
  grid-template-columns: 2rem 2rem 1fr 1px 2rem;
  grid-template-rows: 2.5rem 2rem 1fr;
}

.input-widget div {
  display: grid;
}

.input-widget hr {
  grid-area: hr;
  margin-top: auto;
  margin-bottom: auto;
}
/*
.remoteInputSettingsMain{
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 0.2em;
    border-right: 1px solid rgba(221, 217, 217, 0.2);
    padding-right: .3em;
}
*/
.remoteInputSettingsMainHead {
  grid-area: head;
  grid-template-columns: 1fr;
}
.remoteInputSettingsMainFoot {
  grid-area: content;
  grid-row: span 2;
}

.inputActiveState {
  font-size: 1.1em;
}

.remoteInputSettingsMainFoot p {
  text-align: start;
  height: 100%;
}

.remoteInputSettingsIndex {
  grid-area: index;
  font-size: 1.5em;
}
.remoteInputSettingsEdit {
  grid-area: setting;
  font-size: 1.5em;
}

.remoteInputSettingsNotify {
  grid-area: mail;
  font-size: 1.5em;
}

.SettingsLandingPageBody {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  padding-bottom: 30px;
}

.settingsLink {
  width: 100%;
  text-align: start;
  padding-left: 0.1vw;
}

.isLink {
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .input-widget {
    grid-template-areas:
      "index head hr setting"
      "content content hr setting"
      "content content hr mail"
      "content content hr mail";
    grid-template-columns: 2rem 1fr 2px 2rem;
    grid-template-rows: 2.5rem 2rem 1fr 2rem;
  }
  .remoteInputSettingsMainHead {
    place-content: center;
  }
  .remoteInputSettingsMainFoot {
    grid-row: span 3;
  }
}
