.tooltipContainer {
  cursor: default;
  position: relative;
}

.tooltipText {
  opacity: 0;
  transition: opacity 1s;
  visibility: hidden;
  display: block;
  position: absolute;
  left: calc(100% + 12px);
  top: -2em;
  background-color: var(--bg-black);
  padding: 1rem;
  border: 1px solid var(--orange-600);
  border-radius: 10px 10px 10px 10px;
}

.tooltipContainer:hover > .tooltipText {
  visibility: visible;
  opacity: 0.95;
}

.tooltipContainer .tooltipText::after {
  content: " ";
  position: absolute;
  top: 2.2em;
  right: calc(100% + 1px); /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent var(--orange-600) transparent transparent;
}
