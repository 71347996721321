.LoginForm form {
  left: calc(50vw - 200px);
  top: 80px;
  max-width: 400px;
  position: fixed;
}

.loginForm-form {
  grid-template-rows: 6rem 1rem 1rem 3rem 1rem 3rem 3rem;
}

.LoginLogo {
  content: var(--logo);
  max-width: 350px;
  padding-bottom: 1.5rem;
}

@media only screen and (max-width: 450px) {
  .LoginForm {
    max-width: 400px;
  }
  .LoginForm form {
    width: 300px;
    left: calc((100vw - 300px) / 2);
  }
  .LoginLogo {
    max-width: 250px;
  }
}
